




















































































































































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import AuthModule from '@/store/modules/authModule'
import { Action, Getter } from '@/store/decorators'
import AppLogo from '@core/layouts/components/AppLogo.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { LoginPayload } from '@/models/Auth'
import { User } from '@/models/User'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import SocialLogin from '@/views/auth/SocialLogin.vue'
import { userAbility } from '@/libs/acl/config'
import { VueReCaptcha } from 'vue-recaptcha-v3'

@Component({
  name: 'Login',
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    AppLogo,
    SocialLogin,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  directives: {
    'b-tooltip': VBTooltip
  }
})

export default class Login extends Vue {
  @Action(AuthModule, 'login') private loginUser!: (payload: LoginPayload) => Promise<User>
  @Getter(AuthModule, 'activeUser') private activeUser!: User
  $refs!: { loginForm }
  private appName:string = $themeConfig.app.appName
  private status: string = ''
  private password: string = 'admin'
  private userEmail: string = 'admin@.com'
  private sideImg: string = require('@/assets/images/pages/login-v2.svg')
  private passwordFieldType: string = 'password'

  private mounted () {
    const rules = [email, required]
    Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
    if (this.activeUser) {
      const redirect = '' + (this.$router.currentRoute.query.to || '/')
      // console.log('redirect', redirect)
      this.$router.push(redirect).then().catch(() => {
      })
    }
  }

  private get passwordToggleIcon () {
    return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }

  private get imgUrl () {
    if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      return this.sideImg
    }
    return this.sideImg
  }

  private login () {
    this.$refs.loginForm.validate().then(success => {
      if (success) {
        this.loginHandler()
      }
    }).catch(error => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    })
  }

  private async loginHandler () {
    await this.$recaptchaLoaded()
    const token = await this.$recaptcha('contact')
    if (token) {
      try {
        await this.loginUser({
          email: this.userEmail,
          password: this.password
        })
        if (this.activeUser) {
          const userRole = this.activeUser.userRole || 'public'
          this.$ability.update(userAbility[userRole])
          this.$router.replace(getHomeRouteForLoggedInUser(userRole))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                // position: 'top-right',
                props: {
                  title: 'Welcome',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as customer Now you can start to explore!`
                }
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            // console.log(error)
            // this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: e.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      // console.log(e)
      }
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'recaptcha error',
          variant: 'danger'
        }
      })
    }
  }
}
